export const isServer = () => typeof window === "undefined";

export const isProduction = () => process.env.NODE_ENV === "production";

export const getDeviceBreakpoint = (width: number) => {
  if (width < 640) {
    return "xs";
  } else if (width >= 640 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 1024) {
    return "md";
  } else if (width >= 1024 && width < 1280) {
    return "lg";
  } else if (width >= 1280 && width < 1536) {
    return "xl";
  } else if (width >= 1536) {
    return "2xl";
  }
};

const changeCase = (o: any, modifier: (raw: string) => string) => {
  if (o instanceof Array) {
    return o.map(function (v) {
      if (typeof v === "object") {
        v = changeCase(v, modifier);
      }

      return v;
    });
  } else {
    const newO = {};

    for (var origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        var newKey = modifier(origKey);
        var value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null &&
            typeof value === "object" &&
            !(value instanceof Date))
        ) {
          value = changeCase(value, modifier);
        }

        (newO as any)[newKey] = value;
      }
    }

    return newO;
  }
};

export const snakeCaseString = (raw: string) =>
  raw.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const snakeCase = (o: any) => changeCase(o, snakeCaseString);

export const camelCaseString = (raw: string) => {
  return raw
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );
};

export const camelCase = (o: any) => changeCase(o, camelCaseString);

const alphaNumeric =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const randomString = (len: number, chars: string = alphaNumeric) => {
  var result = "";
  for (var i = len; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
};

export const generateRefreshId = () => randomString(4);

export const isUsernameValid = (username?: string | string[]) =>
  !!username && typeof username === "string" && username[0] === "@";
