import Head from "next/head";
import { isProduction } from "../../common/utils";

const defaultTitle = "PayMeWith: One page for all your social payments.";
const defaultDescription =
  "Create a single page for all your social payments. PayMeWith supports Venmo, Cash App, PayPal, and Google Pay. Claim your username today!";

const PageHead = (props: {
  overrideTitle?: string;
  overrideDescription?: string;
  scripts?: string[];
}) => {
  const { overrideTitle, overrideDescription, scripts } = props;
  const title = overrideTitle || defaultTitle;
  const description = overrideDescription || defaultDescription;

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_URL}/paymewith/cover.png`}
      />
      <meta property="twitter:card" content="summary" />
      <meta name="twitter:site" content="@paymewith" />
      <meta
        name="theme-color"
        content={process.env.NEXT_PUBLIC_COLOR_PRIMARY}
      />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta charSet="UTF-8" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      {!!scripts &&
        scripts.map((s, i) => (
          <script key={i} src={s} type="text/javascript" />
        ))}
      {isProduction() && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
`,
            }}
          />
        </>
      )}
    </Head>
  );
};

export default PageHead;
